import axios, { AxiosError } from 'axios';
import { statusCode400, statusCode500 } from 'constants/httpStatusCodes';
import { ApiErrorType } from 'types/apiError';
import { BadRequestErrorType } from 'types/badRequestErrorType';

const createApiError = (error: AxiosError<{ errorCode?: number }>): ApiErrorType => {
  if (axios.isAxiosError(error)) {
    const data = error.response?.data as BadRequestErrorType;

    return {
      status: error.response?.status || statusCode500,
      error: data.error,
      errorCode: statusCode400,
      errorDescription: data.description,
      errorDetail: data.detail,
    };
  }

  return {
    status: statusCode500,
  };
};

export default createApiError;
